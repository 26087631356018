import type { WebMerchantAppComponentsDto, WebStoreComponentsDto } from '@goparrot/webstore-sdk';
import type { PropsWithChildren } from 'react';
import React, { createContext, useContext, useEffect, useState } from 'react';

export const ComponentsConfigContext = createContext<WebStoreComponentsDto & WebMerchantAppComponentsDto>(
  {} as WebStoreComponentsDto & WebMerchantAppComponentsDto,
);

interface Props {
  initial: WebStoreComponentsDto & WebMerchantAppComponentsDto;
}

export const ComponentsConfigProvider: React.FC<PropsWithChildren<Props>> = ({ initial, children }) => {
  const [state, setState] = useState<WebStoreComponentsDto & WebMerchantAppComponentsDto>(initial);

  useEffect(() => {
    if (initial) {
      setState({ ...initial });
    }
  }, [initial]);

  return <ComponentsConfigContext.Provider value={state}>{state && children}</ComponentsConfigContext.Provider>;
};

export const useComponentsConfig = () => {
  const context = useContext(ComponentsConfigContext);
  if (context === undefined) {
    throw new Error('useComponentsConfig must be used within a ComponentsConfigProvider');
  }
  return context;
};
